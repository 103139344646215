import * as React from "react"
import Layout from "../components/common/layout"
import Heading from "../components/common/heading" 
import {  graphql, Link } from "gatsby"
import { Helmet } from 'react-helmet'
import SubMenu from "../components/common/sub-menu"
import LiveStream from "../components/common/live-stream-table" 
import Slider from 'react-slick';
// markup 
const settings = {
  speed: 500,
  arrows: false,
  autoplay: false,
  dots: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 2,
  variableWidth: true,
};

const locations = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const liveStreamData = _data.find((item: any) => item.block === 'livestream')
  const subMenu = _data.find((item: any) => item.block === 'sub-menu')
  return (
    <Layout>
      <Helmet
        title='Live stream times'
      />
    <SubMenu data={subMenu.data[0]} />
      <div className="tpl-block live-stream-block pt-0">
      <Heading 
      className='mb-0'
      textRight='All class times are in Australian Eastern Standard Time (AEST).'
      title='Live-stream classes' background='bg-white'/>
      <div className="container ">
        <Slider {...settings} className="filter filter-classes">
            <button className="btn btn-outline-secondary active">All classes</button>
            <button className="btn btn-outline-secondary ">Boxing</button>
            <button className="btn btn-outline-secondary ">Cardio Blast</button>
            <button className="btn btn-outline-secondary ">Cross-training</button>
            <button className="btn btn-outline-secondary ">Meditation</button>
            <button className="btn btn-outline-secondary ">Mind Body Wake Up</button>
            <button className="btn btn-outline-secondary ">Pilates</button>
            <button className="btn btn-outline-secondary ">Tai Chi</button>
            <button className="btn btn-outline-secondary ">Yoga</button>
        </Slider>
        <div className="date-boxes d-flex justify-content-between">
            <button className="btn active"><span>T</span><span className='label-date'>Today</span></button>
            <button className="btn btn-date"><span>M</span><span className='label-date'>Monday</span></button>
            <button className="btn btn-date"><span>T</span><span className='label-date'>Tomorrow</span></button>
            <button className="btn btn-date"><span>W</span><span className='label-date'>Wednesday</span></button>
            <button className="btn btn-date"><span>T</span><span className='label-date'>Thursday</span></button>
            <button className="btn btn-date"><span>F</span><span className='label-date'>Friday</span></button>
            <button className="btn btn-date"><span>S</span><span className='label-date'>Saturday</span></button>
            <button className="btn btn-date"><span>S</span><span className='label-date'>Sunday</span></button>
        </div>
        <div className="live-stream-table f-16">
            <LiveStream data={liveStreamData.data[0].data_live_stream[0]} />
            <LiveStream data={liveStreamData.data[1].data_live_stream[0]} />
            <LiveStream data={liveStreamData.data[1].data_live_stream[0]} />
            <LiveStream data={liveStreamData.data[0].data_live_stream[0]} />
        </div>
        <div className="text-muted text-center mt-5 f-16">
            All class times are in Australian Eastern Standard Time (AEST).  
        </div>  
      </div>
    </div>
    </Layout> 
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["livestream"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            date_from
            date_to
            data_live_stream {
              date
              data {
                id
                time
                status
                class
                trainer {
                  name
                  image
                }
              }
            }
            sub_menu{
            title
            links {
                id
                text
                link
                active
            }
            }
          }
        }
      }
    }
  }
`
export default locations
